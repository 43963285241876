import {
  ActivationSelection,
  AppsConfig,
  AppsErrors,
  AppsMobileNumberConfig,
  DisneyAddOnsConfig,
  PurchaseSelection,
} from "./types";

export const cookie = {
  waitingWindow: "isWaitingWindowOpen",
  resync: "isUserSyncData",
  contactId: "user_contactId",
};

export const ACTIVATION_STATUS = {
  active: "ACTIVE",
  inProgress: "IN_PROGRESS",
  available: "AVAILABLE",
};

export const ACTIVE_STATUS = "ACTIVE";
export const mobileNumberField = "mobileNumberField";

export const APPS_CONFIG: AppsConfig = {
  marketingPageUrl: "",
  whatsAppUrl: "",
  maintenanceMode: false,
  maintenanceModePurchase: false,
  blueCheckmarkTickedIcon: "https://acm-assets.eco.astro.com.my/images/icons/blue-checkmark-ticked.svg",
  whiteCheckmarkTickedIcon: "https://acm-assets.eco.astro.com.my/images/icons/white-checkmark-ticked.svg",
  checkmarkUntickedIcon: "https://acm-assets.eco.astro.com.my/images/icons/checkmark-unticked.svg",
  disabledCheckmarkIcon: "https://acm-assets.eco.astro.com.my/images/icons/disabled-checkmark.svg",
  whatsAppIcon: "https://acm-assets.eco.astro.com.my/images/icons/whatsapp-icon.svg",
  waitingRetryDuration: 180,
  resyncRetryDuration: 180,
};

export const APPS_MOBILE_NUMBER_CONFIG: AppsMobileNumberConfig = {
  prefix: {
    isRequired: true,
    items: [],
    preselectedValue: "",
  },
  suffix: {
    isRequired: true,
    htmlInputProps: {},
    regex: "",
    blockedCharactersRegex: "",
    length: 0,
  },
  enableUpdatePhone: false,
  requireUserConsentToUpdatePhone: false,
};

export const ACTIVATION_SELECTION: ActivationSelection = {
  mobilePrefix: "",
  mobileSuffix: "",
  hasConsentedToUpdatePhone: false,
  termsAgreements: [],
  smartcard: null,
};

export const PURCHASE_SELECTION: PurchaseSelection = {
  mobilePrefix: "",
  mobileSuffix: "",
  hasConsentedToUpdatePhone: false,
  termsAgreements: [],
  smartcard: null,
  addOnId: "",
};

export const APPS_ERRORS: AppsErrors = {
  mobilePrefix: {
    hasRequiredError: false,
  },
  mobileSuffix: {
    hasRequiredError: false,
    hasInvalidError: false,
  },
  mobileErrorCode: "",
  showError: false,
};

export const DISNEY_ADDONS_CONFIG: DisneyAddOnsConfig = {
  preselectedAddOnId: "",
  addOns: [],
};
