import { createAsyncThunk, createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

import settings from "@settings";
import { AppState, AppThunk } from "@store";
import { InputFieldKeys, InputState, CheckboxFieldKeys, BaseInputConfig } from "../types";
import {
  AddressState,
  AddressCheckerDirtyResult,
  AddressCheckerRequest,
  AddressCheckerResponse,
  AddressCheckerResult,
  AddressOption,
  AddressFields,
  ConfirmAddressRequest,
  RegisterInterestResponse,
  RegisterInterestRequest,
  RegisterInterestParams,
} from "./types";
import * as constants from "./constants";
import { fetchConfig } from "../config";
import { ResidentialType, SignUpErrorCode } from "../signUp/types";
import { getBasicLeadParameters, getGATrackingLeadParameters } from "../signUp/lead";
import { APIResponse, isErrorResponse, getErrorCode } from "@lib/api";
import { signUpFormPath, registerInterestPath } from "@constants/paths";
import { generateLead } from "@ducks/signUp";
import { pushWithParams } from "@ducks/route";
import { AbTestVariant, getVariantFromUrl } from "@lib/abTest";

export const fetchAddresses = createAsyncThunk<
  AddressCheckerResult[],
  AddressCheckerRequest,
  { state: AppState; rejectValue: string | null }
>("address/fetchAddresses", async ({ abVariant, ...request }, thunkApi) => {
  const { token } = thunkApi.getState().signUp.lead;
  const endpoint =
    abVariant === AbTestVariant.Secondary ? settings.apiEndpoint.checkAddressV2 : settings.apiEndpoint.checkAddress;
  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });
  const data = (await response.json()) as APIResponse<AddressCheckerResponse>;
  if (isErrorResponse(data.response)) {
    const errorCode = getErrorCode(data.response);
    return thunkApi.rejectWithValue(errorCode);
  } else if (!data.response.partialAddressCheckResults.length) {
    return thunkApi.rejectWithValue(null);
  }
  return data.response.partialAddressCheckResults;
});

export const registerInterest = createAsyncThunk<
  void,
  RegisterInterestRequest,
  { state: AppState; rejectValue: { errorCode: string | null; invalidPhoneNumber: boolean; invalidEmail: boolean } }
>("address/registerInterest", async (request, thunkApi) => {
  const { token } = thunkApi.getState().signUp.lead;
  const response = await fetch(settings.apiEndpoint.registerInterest, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });
  const data = (await response.json()) as APIResponse<RegisterInterestResponse>;
  if (isErrorResponse(data.response)) {
    let invalidPhoneNumber = false;
    let invalidEmail = false;
    data.response.errors.forEach((error) => {
      const { errorCode, errorAttributes } = error;
      if (errorCode === SignUpErrorCode.Validation && errorAttributes && errorAttributes.context) {
        const key = errorAttributes.context.key;
        switch (errorAttributes.type) {
          case SignUpErrorCode.InvalidPhone:
            if (key === "phoneNumber") invalidPhoneNumber = true;
            break;
          case SignUpErrorCode.InvalidEmail:
            if (key === "email") invalidEmail = true;
            break;
        }
      }
    });
    return thunkApi.rejectWithValue({ errorCode: getErrorCode(data.response), invalidPhoneNumber, invalidEmail });
  }
});

const initialState: AddressState = {
  config: constants.config,
  searchStatus: {
    loading: false,
    error: false,
    success: false,
  },
  registerInterestStatus: {
    loading: false,
    error: false,
    success: false,
  },
  regex: constants.regex,
  fields: constants.fields,
  addresses: [],
  keywords: [],
  filteredAddresses: [],
  selectedAddress: null,
  showError: false,
  addressNotCovered: false,
  showRegisterInterest: false,
  hasQueryModified: false,
  hasAddressModified: false,
  hasSearched: false,
  showAddressListing: false,
  addressNotListed: false,
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    residentialTypeUpdated(state, action: PayloadAction<ResidentialType>) {
      const value = action.payload;
      if (value !== state.fields.residentialType.value) {
        state.fields.residentialType.value = action.payload;
        state.fields.residentialType.hasRequiredError = state.config.residentialType.isRequired && !value;
        state.fields.houseNumber = resetField(state.config.houseNumber);
        state.fields.streetName = resetField(state.config.streetName);
        state.fields.streetType = resetField(state.config.streetType);
        state.fields.street = resetField(state.config.street);
        state.fields.unit = resetField(state.config.unit);
        state.fields.block = resetField(state.config.block);
        state.fields.building = resetField(state.config.building);
        state.showError = false;
        state.hasQueryModified = true;
        state.hasAddressModified = true;
      }
    },
    fieldUpdated(state, action: PayloadAction<{ field: InputFieldKeys; value: string }>) {
      const { field, value } = action.payload;
      if (value !== state.fields[field].value) {
        state.fields[field].value = value;
        state.fields[field].hasRequiredError = state.config[field].isRequired && !value.trim();
        if (state.regex[field]) {
          const regex = new RegExp(state.regex[field] ?? "");
          state.fields[field].hasInvalidError = !!value && !regex.test(value);
        }

        switch (field) {
          case "building":
            state.hasQueryModified = true;
            state.hasAddressModified = true;
            break;
          case "block":
          case "houseNumber":
          case "unit":
            state.hasAddressModified = true;
            break;
          case "streetType":
          case "streetName":
            const streetType = state.fields.streetType.value;
            const streetName = state.fields.streetName.value;
            const street = `${streetType} ${streetName}`;
            state.fields.street = {
              value: street,
              hasRequiredError: state.config.street.isRequired && !street.trim(),
              hasInvalidError: state.regex.street ? !new RegExp(state.regex.street).test(street) : false,
            };
            state.hasQueryModified = true;
            state.hasAddressModified = true;
            break;
          case "postcode":
            state.fields.postcode.hasInvalidError = !!value && !new RegExp(/^[0-9]{5}$/).test(value);
            state.hasQueryModified = true;
            state.hasAddressModified = true;
            break;
          case "mobilePrefix":
            const mobile = state.fields.mobileNumber.value;
            mobile &&
              state.regex.mobileNumber &&
              new RegExp(state.regex.mobileNumber).test(mobile) &&
              (state.fields.mobileNumber.hasInvalidError = false);
            break;
        }
      }
    },
    checkboxUpdated(state, action: PayloadAction<{ field: CheckboxFieldKeys; value: boolean }>) {
      const { field, value } = action.payload;
      if (value !== state.fields[field].value) {
        state.fields[field].value = value;
        state.fields[field].hasRequiredError = state.config[field].isRequired && !value;
      }
    },
    queryValidated(state, action: PayloadAction<AbTestVariant>) {
      state.showError = !isQueryValid(state.fields, action.payload);
    },
    keywordAdded(state, action: PayloadAction<string>) {
      if (state.keywords.indexOf(action.payload) < 0) {
        state.keywords.push(action.payload);
        state.filteredAddresses = filterAddresses(state.addresses, state.keywords);
      }
    },
    keywordRemoved(state, action: PayloadAction<string>) {
      const index = state.keywords.findIndex((word) => word === action.payload);
      if (index >= 0) {
        state.keywords.splice(index, 1);
        state.filteredAddresses = filterAddresses(state.addresses, state.keywords);
      }
    },
    addressesFiltered(state, action: PayloadAction<string>) {
      state.filteredAddresses = filterAddresses(state.addresses, [action.payload]);
    },
    addressFilterReset(state) {
      state.filteredAddresses = state.addresses;
    },
    addressSelected(state, action: PayloadAction<AddressCheckerResult>) {
      state.selectedAddress = action.payload;
      state.addressNotListed = false;
      state.hasAddressModified = false;
    },
    toggleRegisterInterest(state, action: PayloadAction<boolean>) {
      state.showRegisterInterest = action.payload;
    },
    formValidated(state, action: PayloadAction<AbTestVariant>) {
      if (!isComplete(state, action.payload)) state.showError = true;
    },
    resetCheckCoverageErrors(state) {
      // TODO: hide error using connected router action
      state.showError = false;
    },
    toggleAddressListingModal(state, action: PayloadAction<boolean>) {
      state.showAddressListing = action.payload;
      if (action.payload) state.hasAddressModified = false;
    },
    toggleAddressNotListed(state, action: PayloadAction<boolean>) {
      state.selectedAddress = null;
      state.addressNotListed = action.payload;
    },
    addressModificationCompleted(state) {
      state.hasAddressModified = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.fulfilled, (state, action) => {
        const addressConfig = action.payload.address;
        if (addressConfig) {
          state.config = addressConfig;
          state.regex = action.payload.regex ?? state.regex;
        }
      })
      .addCase(fetchAddresses.pending, (state, action) => {
        state.searchStatus = {
          loading: true,
          error: false,
          success: false,
        };
        state.hasQueryModified = false;
        state.hasAddressModified = false;

        if (action.meta.arg.abVariant === AbTestVariant.Secondary) {
          state.selectedAddress = null;
          state.addressNotListed = false;
        }
      })
      .addCase(fetchAddresses.rejected, (state, action) => {
        state.searchStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) state.searchStatus.errorCode = action.payload;
        state.addressNotCovered = true;
        state.showRegisterInterest = true;
        state.showError = false;
        state.selectedAddress = null;

        if (action.meta.arg.abVariant === AbTestVariant.Secondary) {
          state.addressNotListed = true;
          state.addresses = [];
        }
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.searchStatus = {
          loading: false,
          error: false,
          success: true,
        };
        const { residenceType: residentialType, abVariant } = action.meta.arg;
        const addresses = action.payload.reduce<AddressOption[]>((list, result) => {
          const data = sanitizeAddress(result);
          const formattedAddress = formatAddress({
            address: data,
            residentialType,
            abVariant,
          });
          if (formattedAddress) list.push({ id: nanoid(), data, formattedAddress });
          return list;
        }, []);
        state.addresses = addresses;
        state.filteredAddresses = addresses;
        state.addressNotCovered = false;
        state.showRegisterInterest = false;
        state.showError = false;
        state.selectedAddress = null;

        if (action.meta.arg.abVariant === AbTestVariant.Secondary) {
          state.hasSearched = true;
          state.showAddressListing = addresses.length > 1;
          state.addressNotListed = false;
          if (addresses.length === 1) state.selectedAddress = addresses[0].data;
        }
      })
      .addCase(registerInterest.pending, (state) => {
        state.registerInterestStatus = {
          loading: true,
          error: false,
          success: false,
        };
      })
      .addCase(registerInterest.rejected, (state, action) => {
        state.registerInterestStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) {
          const { errorCode, invalidPhoneNumber, invalidEmail } = action.payload;
          if (invalidPhoneNumber) state.fields.mobileNumber.hasInvalidError = true;
          if (invalidEmail) state.fields.email.hasInvalidError = true;
          if (invalidPhoneNumber || invalidEmail) {
            state.showError = true;
            state.registerInterestStatus.error = false; // TODO: refactor error handling - this is a quick solution to handle for now
          } else if (errorCode) state.registerInterestStatus.errorCode = errorCode;
        }
      })
      .addCase(registerInterest.fulfilled, (state) => {
        state.registerInterestStatus = {
          loading: false,
          error: false,
          success: true,
        };
      });
  },
});

const {
  residentialTypeUpdated,
  fieldUpdated,
  checkboxUpdated,
  queryValidated,
  keywordAdded,
  keywordRemoved,
  addressesFiltered,
  addressFilterReset,
  addressSelected,
  toggleRegisterInterest,
  formValidated,
  resetCheckCoverageErrors,
  toggleAddressListingModal,
  toggleAddressNotListed,
  addressModificationCompleted,
} = addressSlice.actions;

export {
  residentialTypeUpdated,
  fieldUpdated,
  checkboxUpdated,
  keywordAdded,
  keywordRemoved,
  addressesFiltered,
  addressFilterReset,
  addressSelected,
  toggleRegisterInterest,
  resetCheckCoverageErrors,
  toggleAddressListingModal,
  toggleAddressNotListed,
};

export default addressSlice.reducer;

/* Additional actions */
export const checkCoveragePageLoad = createAction("address/pageLoad");
export const searchButtonClick = createAction("address/searchButtonClick");
export const trackNotListedOption = createAction<boolean>("address/trackNotListedOption");
export const trackTncLink = createAction<string>("address/trackTncLink");
export const confirmAddress = createAction<ConfirmAddressRequest>("address/conlanfirmAddress");
export const trackCompleteRegisterInterest = createAction("address/trackCompleteRegisterInterest");
export const addressRejected = createAction("address/addressRejected");

/* Thunks */
export const submitLead = (): AppThunk => async (dispatch, getState) => {
  const appState = getState();
  if (!appState.signUp.lead.id)
    await dispatch(generateLead({ ...getBasicLeadParameters(appState), ...getGATrackingLeadParameters(appState) }));
  if (getState().signUp.leadStatus.success) dispatch(checkCoveragePageLoad());
};

export const searchAddress = (): AppThunk => (dispatch, getState) => {
  const abVariant = getVariantFromUrl(getState().router.location.search);
  dispatch(queryValidated(abVariant));
  if (!getState().address.showError) {
    const { fields, hasQueryModified, addresses } = getState().address;
    const {
      residentialType: { value: residenceType },
      postcode: { value: postalCode },
      streetType: { value: streetType },
      streetName: { value: streetName },
      building: { value: buildingName },
    } = fields;

    dispatch(searchButtonClick()); // trigger GA tag

    if (abVariant === AbTestVariant.Secondary && !hasQueryModified) {
      if (addresses.length > 1) dispatch(toggleAddressListingModal(true));
      else if (addresses.length === 1) dispatch(addressSelected(addresses[0].data));
      else dispatch(addressModificationCompleted());
      return;
    }

    switch (residenceType) {
      case ResidentialType.SingleDwelling:
        dispatch(
          fetchAddresses({
            residenceType,
            postalCode,
            streetName: `${streetType} ${streetName}`,
            abVariant,
          })
        );
        break;
      case ResidentialType.MultiDwelling:
        dispatch(
          fetchAddresses({
            residenceType,
            postalCode,
            buildingName,
            abVariant,
          })
        );
        break;
    }
  }
};

export const selectAddress = (address: AddressCheckerResult): AppThunk => (dispatch) => {
  dispatch(addressSelected(address));
  dispatch(toggleAddressListingModal(false));
};

export const selectNotListedOption = (): AppThunk => (dispatch) => {
  dispatch(toggleAddressNotListed(true));
  dispatch(toggleAddressListingModal(false));
  dispatch(trackNotListedOption(true));
};

export const declareIncorrectAddress = (): AppThunk => (dispatch) => {
  dispatch(toggleAddressNotListed(true));
  dispatch(addressRejected());
};

export const validateForm = (): AppThunk => (dispatch, getState) => {
  const abVariant = getVariantFromUrl(getState().router.location.search);
  dispatch(formValidated(abVariant));
};

export const proceedToSignUp = (): AppThunk => (dispatch, getState) => {
  const abVariant = getVariantFromUrl(getState().router.location.search);
  if (isComplete(getState().address, abVariant)) {
    const { fields, selectedAddress } = getState().address;
    // const residentialType = fields.residentialType.value;
    const {
      residentialType: { value: residentialType },
      allowDishInstallation: { value: allowDishInstallation },
    } = fields;
    if (residentialType !== null && selectedAddress !== null) {
      const {
        area,
        buildingName,
        cableType,
        city,
        ispName,
        floorNo,
        postCode,
        stateCode,
        state,
        streetName,
        unitNo,
      } = selectedAddress;
      const selectedState: string = state ? state : stateCode;
      const address: ConfirmAddressRequest = {
        cableType,
        serviceProvider: ispName,
        residentialType,
        postcode: postCode,
        street: streetName,
        area,
        city,
        state: selectedState,
        allowDishInstallation,
      };
      if (floorNo) address.floor = floorNo;
      switch (residentialType) {
        case ResidentialType.SingleDwelling:
          if (abVariant === AbTestVariant.Secondary) address.houseNumber = fields.houseNumber.value;
          else address.houseNumber = unitNo || fields.houseNumber.value;
          break;
        case ResidentialType.MultiDwelling:
          address.building = buildingName;
          if (abVariant === AbTestVariant.Secondary) address.unit = fields.unit.value;
          else address.unit = unitNo || fields.unit.value;
          address.block = fields.block.value; // TODO: no block attribute from API
          break;
      }
      dispatch(confirmAddress(address));
      dispatch(pushWithParams(signUpFormPath));
    }
  }
};

export const submitRegisterInterest = (): AppThunk => async (dispatch, getState) => {
  const address = getState().address;
  const abVariant = getVariantFromUrl(getState().router.location.search);
  if (isComplete(address, abVariant)) {
    const request: RegisterInterestRequest = {
      ...getBasicLeadParameters(getState()),
      ...getGATrackingLeadParameters(getState()),
      ...getRegisterInterestParameters(address.fields),
    };
    delete request.package.cableType;
    delete request.package.serviceProvider;
    const response = await dispatch(registerInterest(request));
    if (registerInterest.fulfilled.match(response)) dispatch(pushWithParams(registerInterestPath));
  }
};

/* Utility functions */
function resetField(config: BaseInputConfig): InputState {
  return {
    value: "",
    hasRequiredError: config.isRequired,
    hasInvalidError: false,
  };
}

function isValid(state: InputState) {
  return !(state.hasRequiredError || state.hasInvalidError);
}

function isQueryValid(fields: AddressFields, variant: AbTestVariant) {
  const { building, houseNumber, postcode, residentialType, streetName, streetType, unit } = fields;
  switch (residentialType.value) {
    case ResidentialType.SingleDwelling:
      return variant === AbTestVariant.Secondary
        ? [houseNumber, postcode, streetType, streetName].every(isValid)
        : [postcode, streetType, streetName].every(isValid);
    case ResidentialType.MultiDwelling:
      return variant === AbTestVariant.Secondary
        ? [unit, postcode, building].every(isValid)
        : [postcode, building].every(isValid);
    default:
      return false;
  }
}

function isSelectedAddressValid(params: {
  selectedAddress: AddressCheckerResult | null;
  fields: AddressFields;
  abVariant?: AbTestVariant;
}) {
  const { selectedAddress, fields, abVariant } = params;
  if (selectedAddress === null) return false;

  const {
    allowDishInstallation,
    block,
    building,
    houseNumber,
    postcode,
    residentialType,
    streetType,
    streetName,
    unit,
  } = fields;
  const { cableType, ispName, unitNo } = selectedAddress;

  const fieldsToCheck: InputState[] = [];
  switch (residentialType.value) {
    case ResidentialType.SingleDwelling:
      if (abVariant === AbTestVariant.Secondary) {
        fieldsToCheck.push(houseNumber, postcode, streetType, streetName);
      } else {
        if (allowDishInstallation.value) return false; // only applicable to MDU
        if (!unitNo) fieldsToCheck.push(houseNumber);
        fieldsToCheck.push(postcode, streetType, streetName);
      }
      break;
    case ResidentialType.MultiDwelling:
      if (abVariant === AbTestVariant.Secondary) {
        fieldsToCheck.push(unit, block, building, postcode);
      } else {
        if (!unitNo) fieldsToCheck.push(unit);
        fieldsToCheck.push(block, building, postcode);
      }
      break;
    default:
      return false;
  }

  return Boolean(fieldsToCheck.every(isValid) && cableType && ispName);
}

function isRegisterInterestValid(params: { fields: AddressFields; abVariant?: AbTestVariant }) {
  const { fields, abVariant } = params;
  const {
    area,
    block,
    building,
    email,
    houseNumber,
    mobileNumber,
    mobilePrefix,
    name,
    postcode,
    residentialType,
    street,
    streetName,
    streetType,
    tncAgreement,
    unit,
  } = fields;

  const fieldsToCheck: InputState[] = [];

  /* Address fields */
  switch (residentialType.value) {
    case ResidentialType.SingleDwelling:
      if (abVariant === AbTestVariant.Secondary) fieldsToCheck.push(houseNumber, streetType, streetName, postcode);
      else fieldsToCheck.push(houseNumber, street, area);
      break;
    case ResidentialType.MultiDwelling:
      if (abVariant === AbTestVariant.Secondary) fieldsToCheck.push(unit, block, building, postcode);
      else fieldsToCheck.push(building, unit, block, street, area);
      break;
  }

  /* Contact info fields */
  fieldsToCheck.push(name, mobilePrefix, mobileNumber, email);

  return fieldsToCheck.every(isValid) && tncAgreement.value;
}

export function isComplete(state: AddressState, abVariant?: AbTestVariant) {
  const { showRegisterInterest, addressNotListed, fields, selectedAddress } = state;
  return showRegisterInterest || addressNotListed
    ? isRegisterInterestValid({ fields, abVariant })
    : isSelectedAddressValid({ selectedAddress, fields, abVariant });
}

function isAddressDirty(address: unknown): address is AddressCheckerDirtyResult {
  return Object.values(address as AddressCheckerResult).some((value) => value === null);
}

function sanitizeAddress(address: AddressCheckerResult): AddressCheckerResult {
  if (isAddressDirty(address)) {
    return {
      ...address,
      unitNo: address.unitNo ?? "",
      streetName: address.streetName ?? "",
      area: address.area ?? "",
    };
  }
  return address;
}

function formatAddress(params: {
  address: AddressCheckerResult;
  residentialType: ResidentialType;
  abVariant: AbTestVariant;
}) {
  const { address, residentialType, abVariant } = params;
  const { area, buildingName, streetName, unitNo } = address;
  let components: string[] = [];
  switch (residentialType) {
    case ResidentialType.SingleDwelling:
      if (abVariant === AbTestVariant.Secondary) components = [streetName, area];
      else components = [unitNo, streetName, area];
      break;
    case ResidentialType.MultiDwelling:
      if (abVariant === AbTestVariant.Secondary) components = [buildingName, area];
      else components = [unitNo, buildingName, streetName, area];
      break;
  }
  return components
    .filter((component) => component.trim().length)
    .join(", ")
    .replace(/\s\s+/g, " ");
}

function filterAddresses(addresses: AddressOption[], keywords: string[]) {
  return addresses.filter((address) => {
    return keywords.every((keyword) => {
      return address.formattedAddress.toLowerCase().includes(keyword.toLowerCase());
    });
  });
}

function getRegisterInterestParameters(fields: AddressFields) {
  const {
    name,
    mobilePrefix,
    mobileNumber,
    email,
    area,
    postcode,
    streetType,
    streetName,
    street,
    residentialType,
    houseNumber,
    building,
    unit,
    block,
  } = fields;
  const params: RegisterInterestParams = {
    name: name.value,
    phoneNumber: `${mobilePrefix.value}${mobileNumber.value}`,
    email: email.value,
    postal: postcode.value,
    streetName: street.value || `${streetType.value} ${streetName.value}`,
    residenceType: residentialType.value ?? ResidentialType.SingleDwelling,
  };
  if (area.value) params.town = area.value;
  switch (residentialType.value) {
    case ResidentialType.SingleDwelling:
      params.houseNumber = houseNumber.value;
      break;
    case ResidentialType.MultiDwelling:
      params.buildingName = building.value;
      params.unitNumber = unit.value;
      params.block = block.value;
      break;
  }
  // TODO: allow dish installation
  return params;
}
