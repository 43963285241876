import { createSlice, isFulfilled } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { AppThunk } from "@store";
import {
  disneyActivatePendingPath,
  disneyActivateThankYouPath,
  disneyContactThankYouPath,
  disneyPurchaseThankYouPath,
} from "@constants/paths";
import { fetchAppsConfig } from "@ducks/config";
import { AppsOtpState } from "./types";
import { PENDING_ACTIVATION } from "./constants";
import {
  validateActivation,
  activate,
  generateOtpForPurchase,
  purchase,
  activatePurchase,
  validateActivationForDuplicatePurchase,
} from "../disneyActions";
import { generateDisneyLead, verifyDisneyLead } from "../leadActions";
import { errorCodes } from "../constants";

const initialState: AppsOtpState = {
  config: {
    maxAttempts: 3,
    resendDuration: 30,
    blockedCharactersRegex: "",
    length: 0,
  },
  generationAttemptsRemaining: 3,
  generationStatus: {
    loading: false,
    error: false,
    success: false,
  },
  verificationStatus: {
    loading: false,
    error: false,
    success: false,
  },
  showOtpModal: false,
  activationValidateToken: "",
  purchaseValidateOtpToken: "",
  isDuplicatePurchaseValidationError: false,
  activatePending: false,
  leadToken: "",
};

const appsOtpSlice = createSlice({
  name: "appsOtp",
  initialState,
  reducers: {
    resetAttempt(state) {
      state.generationAttemptsRemaining = state.config.maxAttempts;
      state.generationStatus = {
        loading: false,
        error: false,
        success: false,
      };
      state.verificationStatus = {
        loading: false,
        error: false,
        success: false,
      };
    },
    otpModalOpened(state) {
      state.showOtpModal = true;
    },
    otpModalClosed(state) {
      state.showOtpModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppsConfig.fulfilled, (state, action) => {
        if (action.payload.otp) {
          state.config = action.payload.otp;
          state.generationAttemptsRemaining = state.config.maxAttempts;
        }
      })
      .addCase(validateActivation.pending, (state) => {
        state.generationStatus = {
          loading: true,
          error: false,
          success: false,
        };
        state.verificationStatus = {
          loading: false,
          error: false,
          success: false,
        };
        state.generationAttemptsRemaining--;
      })
      .addCase(validateActivation.rejected, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) {
          const { errorCode } = action.payload;
          if (errorCode === errorCodes.failedToGenerateOtp) {
            state.showOtpModal = true;
            state.activationValidateToken = "";
          } else state.generationAttemptsRemaining = state.config.maxAttempts;
        }
      })
      .addCase(validateActivation.fulfilled, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.showOtpModal = true;
        state.activationValidateToken = action.payload;
      })
      .addCase(activate.pending, (state) => {
        state.verificationStatus = {
          loading: true,
          error: false,
          success: false,
        };
      })
      .addCase(activate.rejected, (state, action) => {
        state.verificationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) {
          const { errorCode } = action.payload;
          switch (errorCode) {
            case errorCodes.failedToVerifyOtp:
            case errorCodes.validationErrorToken:
              state.verificationStatus.errorCode = errorCode;
              break;
            default:
              state.showOtpModal = false;
              break;
          }
        }
      })
      .addCase(activate.fulfilled, (state, action) => {
        state.verificationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.showOtpModal = false;
        state.activatePending = isPendingActivation(action.payload);
      })
      .addCase(generateOtpForPurchase.pending, (state) => {
        state.generationStatus = {
          loading: true,
          error: false,
          success: false,
        };
        state.verificationStatus = {
          loading: false,
          error: false,
          success: false,
        };
        state.generationAttemptsRemaining--;
        state.isDuplicatePurchaseValidationError = false;
      })
      .addCase(generateOtpForPurchase.rejected, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        state.isDuplicatePurchaseValidationError = false;
        if (action.payload) {
          const { errorCode } = action.payload;
          if (errorCode === errorCodes.failedToGenerateOtp) {
            state.showOtpModal = true;
            state.purchaseValidateOtpToken = "";
          }
        }
      })
      .addCase(generateOtpForPurchase.fulfilled, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.showOtpModal = true;
        state.purchaseValidateOtpToken = action.payload;
        state.isDuplicatePurchaseValidationError = false;
      })
      .addCase(purchase.pending, (state) => {
        state.verificationStatus = {
          loading: true,
          error: false,
          success: false,
        };
      })
      .addCase(purchase.rejected, (state, action) => {
        state.verificationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) {
          const { errorCode } = action.payload;
          switch (errorCode) {
            case errorCodes.failedToVerifyOtp:
            case errorCodes.validationErrorToken:
              state.verificationStatus.errorCode = errorCode;
              break;
            default:
              state.showOtpModal = false;
              break;
          }
        }
      })
      .addCase(purchase.fulfilled, (state, action) => {
        state.verificationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.activationValidateToken = action.payload;
      })
      .addCase(activatePurchase.pending, (state) => {
        state.verificationStatus = {
          loading: true,
          error: false,
          success: false,
        };
      })
      .addCase(activatePurchase.rejected, (state, action) => {
        state.verificationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) {
          const { errorCode } = action.payload;
          switch (errorCode) {
            case errorCodes.failedToVerifyOtp:
            case errorCodes.validationErrorToken:
              state.verificationStatus.errorCode = errorCode;
              break;
            default:
              state.showOtpModal = false;
              break;
          }
        }
      })
      .addCase(activatePurchase.fulfilled, (state, action) => {
        state.verificationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.showOtpModal = false;
        state.isDuplicatePurchaseValidationError = false;
        state.activatePending = isPendingActivation(action.payload);
      })
      .addCase(validateActivationForDuplicatePurchase.pending, (state) => {
        state.generationStatus = {
          loading: true,
          error: false,
          success: false,
        };
        state.verificationStatus = {
          loading: false,
          error: false,
          success: false,
        };
        state.generationAttemptsRemaining--;
      })
      .addCase(validateActivationForDuplicatePurchase.rejected, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) {
          const { errorCode } = action.payload;
          if (errorCode === errorCodes.failedToGenerateOtp) {
            state.showOtpModal = true;
            state.activationValidateToken = "";
            state.isDuplicatePurchaseValidationError = true;
          } else state.generationAttemptsRemaining = state.config.maxAttempts;
        }
      })
      .addCase(validateActivationForDuplicatePurchase.fulfilled, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.showOtpModal = true;
        state.activationValidateToken = action.payload;
        state.isDuplicatePurchaseValidationError = true;
      })
      .addCase(generateDisneyLead.pending, (state) => {
        state.generationStatus = {
          loading: true,
          error: false,
          success: false,
        };
        state.verificationStatus = {
          loading: false,
          error: false,
          success: false,
        };
        state.generationAttemptsRemaining--;
      })
      .addCase(generateDisneyLead.rejected, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) {
          const { errorCode } = action.payload;
          if (errorCode === errorCodes.failedToGenerateOtp) {
            state.showOtpModal = true;
            state.leadToken = "";
          } else state.generationAttemptsRemaining = state.config.maxAttempts;
        }
      })
      .addCase(generateDisneyLead.fulfilled, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.showOtpModal = true;
        state.leadToken = action.payload;
      })
      .addCase(verifyDisneyLead.pending, (state) => {
        state.verificationStatus = {
          loading: true,
          error: false,
          success: false,
        };
      })
      .addCase(verifyDisneyLead.rejected, (state, action) => {
        state.verificationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) {
          const { errorCode } = action.payload;
          switch (errorCode) {
            case errorCodes.failedToVerifyOtp:
            case errorCodes.validationErrorToken:
              state.verificationStatus.errorCode = errorCode;
              break;
            default:
              state.showOtpModal = false;
              break;
          }
        }
      })
      .addCase(verifyDisneyLead.fulfilled, (state, action) => {
        state.verificationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.showOtpModal = false;
      });
  },
});

export const { resetAttempt, otpModalOpened, otpModalClosed } = appsOtpSlice.actions;

export default appsOtpSlice.reducer;

export const generateOtpActivate = (): AppThunk => (dispatch, getState) => {
  if (getState().appsOtp.generationAttemptsRemaining) dispatch(validateActivation());
};

export const verifyOtpActivate = (otp: string): AppThunk => async (dispatch, getState) => {
  const result = await dispatch(activate(otp));
  if (activate.fulfilled.match(result))
    getState().appsOtp.activatePending
      ? dispatch(push(`/${disneyActivatePendingPath}`))
      : dispatch(push(`/${disneyActivateThankYouPath}`));
};

export const generateOtpPurchase = (): AppThunk => (dispatch, getState) => {
  if (getState().appsOtp.generationAttemptsRemaining) dispatch(generateOtpForPurchase());
};

export const verifyOtpPurchase = (otp: string): AppThunk => async (dispatch, getState) => {
  const purchaseResult = await dispatch(purchase(otp));
  if (purchase.fulfilled.match(purchaseResult)) {
    const activateResult = await dispatch(activatePurchase(otp));
    if (activatePurchase.fulfilled.match(activateResult))
      getState().appsOtp.activatePending
        ? dispatch(push(`/${disneyActivatePendingPath}`))
        : dispatch(push(`/${disneyPurchaseThankYouPath}`));
  }
};

export const generateOtpForDuplicatePurchase = (): AppThunk => (dispatch, getState) => {
  if (getState().appsOtp.generationAttemptsRemaining) dispatch(validateActivationForDuplicatePurchase());
};

export const verifyOtpForDuplicatePurchase = (otp: string): AppThunk => async (dispatch, getState) => {
  const result = await dispatch(activatePurchase(otp));
  if (activatePurchase.fulfilled.match(result))
    getState().appsOtp.activatePending
      ? dispatch(push(`/${disneyActivatePendingPath}`))
      : dispatch(push(`/${disneyActivateThankYouPath}`));
};

export const generateOtpContact = (): AppThunk => (dispatch, getState) => {
  if (getState().appsOtp.generationAttemptsRemaining) dispatch(generateDisneyLead());
};

export const verifyOtpContact = (otp: string): AppThunk => async (dispatch) => {
  const result = await dispatch(verifyDisneyLead(otp));
  if (isFulfilled(result)) dispatch(push(`/${disneyContactThankYouPath}`));
};

export const resetOtp = (): AppThunk => (dispatch, getState) => {
  const {
    appsOtp: { verificationStatus },
  } = getState();
  dispatch(otpModalClosed());
  if (!verificationStatus.success) dispatch(resetAttempt());
};

function isPendingActivation(status: string): boolean {
  return status === PENDING_ACTIVATION;
}
