import { createMuiTheme } from "@material-ui/core";

import breakpoints from "./breakpoints";
import mixins from "./mixins";
import overrides from "./overrides";
import palette from "./palette";
import props from "./props";
import spacing from "./spacing";
import typography from "./typography";

const defaultTheme = createMuiTheme({
  breakpoints,
  mixins,
  overrides,
  palette,
  props,
  spacing,
  typography,
});

export default defaultTheme;
