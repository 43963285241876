import { createSlice, PayloadAction, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import * as constants from "./constants";
import {
  SignUpState,
  Gender,
  ResidentialType,
  AddDocument,
  UpdateEmail,
  UpdatePostcode,
  FetchPostcodeReturn,
  FetchPostcodeResponse,
  GenerateLeadReturn,
  GenerateLeadResponse,
  UpdateLeadResponse,
  UpdateLeadReturn,
  UpdateLeadParameters,
  UpdateBFLeadParameters,
  UpdateLeadErrorResponse,
  GenerateSignedUrlReturn,
  GenerateSignedUrlParameters,
  GenerateSignedUrlResponse,
  SignUpErrorCode,
  PortalType,
  GenerateLeadParameters,
} from "./types";
import { fetchConfig } from "@ducks/config";
import { fetchCustomerInfo } from "@ducks/customerInfo";
import { AppThunk, AppState } from "@store";
import settings from "@settings";
import { APIResponse, isErrorResponse, getErrorCode } from "@lib/api";
import {
  getBasicLeadParameters,
  getCustomerLeadParameters,
  getBFCustomerLeadParameters,
  getGATrackingLeadParameters,
} from "./lead";
import { confirmAddress, fieldUpdated } from "@ducks/address";
import { getShopUrlQueryString, isInitAction } from "@ducks/shop";

export const fetchPostcode = createAsyncThunk<
  FetchPostcodeReturn,
  string,
  { state: AppState; rejectValue: string | null }
>("signUp/fetchPostcode", async (postcode, thunkApi) => {
  let postcodeParams = `?postalCode=${postcode}`;
  if (process.env.REACT_APP_USE_MOCK_POSTCODE === "true" && postcode === process.env.REACT_APP_MOCK_POSTCODE)
    postcodeParams += `&mock=true`;
  const response = await fetch(`${settings.apiEndpoint.postcode}${postcodeParams}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = (await response.json()) as APIResponse<FetchPostcodeResponse>;

  if (isErrorResponse(data.response)) return thunkApi.rejectWithValue(getErrorCode(data.response));

  const { area, city, state } = data.response;
  return {
    area,
    city,
    state,
  };
});

export const generateLead = createAsyncThunk<
  GenerateLeadReturn,
  GenerateLeadParameters,
  { state: AppState; rejectValue: string | null }
>("signUp/generateLead", async (lead, thunkApi) => {
  const response = await fetch(settings.apiEndpoint.generateLead, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lead),
  });
  const data = (await response.json()) as APIResponse<GenerateLeadResponse>;

  if (isErrorResponse(data.response)) return thunkApi.rejectWithValue(getErrorCode(data.response));

  const { leadId, token } = data.response;
  return {
    leadId,
    token,
  };
});

export const updateLead = createAsyncThunk<
  UpdateLeadReturn,
  void,
  { state: AppState; rejectValue: UpdateLeadErrorResponse }
>("signUp/updateLead", async (request, thunkApi) => {
  const appState = thunkApi.getState();
  const isLoggedIn = appState.customerInfo.fetchStatus.success;
  const endpoint = isLoggedIn ? settings.apiEndpoint.updateBFLead : settings.apiEndpoint.updateLead;

  const customerLeadParameters = isLoggedIn
    ? getBFCustomerLeadParameters(appState.signUp, appState.customerInfo)
    : getCustomerLeadParameters(appState.signUp);

  const parameters: UpdateLeadParameters | UpdateBFLeadParameters = {
    ...getBasicLeadParameters(appState),
    ...customerLeadParameters,
    orderSummaryQuery: getShopUrlQueryString(appState.shop.urlQueryParameters, appState.router.location.search),
  };

  const token = appState.signUp.lead.token;
  const response = await fetch(endpoint, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(parameters),
  });
  const data = (await response.json()) as APIResponse<UpdateLeadResponse>;

  // TODO: refactor how we handle error responses
  if (isErrorResponse(data.response)) {
    let invalidMobile = false;
    let invalidAlternateNumber = false;
    let invalidEmail = false;

    data.response.errors.forEach((error) => {
      const { errorCode, errorAttributes } = error;
      if (errorCode === SignUpErrorCode.Validation && errorAttributes && errorAttributes.context) {
        const key = errorAttributes.context.key;
        switch (errorAttributes.type) {
          case SignUpErrorCode.InvalidPhone:
            switch (key) {
              case "phoneNumber":
                invalidMobile = true;
                break;
              case "alternatePhoneNumber":
                invalidAlternateNumber = true;
                break;
            }
            break;
          case SignUpErrorCode.InvalidEmail:
            if (key === "email") invalidEmail = true;
            break;
        }
      }
    });
    return thunkApi.rejectWithValue({
      errorCode: getErrorCode(data.response),
      invalidMobile,
      invalidAlternateNumber,
      invalidEmail,
    });
  }

  const { verificationRequired } = data.response;
  return { verificationRequired };
});

export const generateSignedUrl = createAsyncThunk<
  GenerateSignedUrlReturn,
  { token: string; meta: GenerateSignedUrlParameters },
  { state: AppState; rejectWithValue: string | null }
>("signUp/generateSignedUrl", async (parameters, thunkApi) => {
  const { token, meta } = parameters;
  const response = await fetch(settings.apiEndpoint.generateSignUrl, {
    method: "POST",
    body: JSON.stringify(meta),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const data = (await response.json()) as APIResponse<GenerateSignedUrlResponse>;

  if (isErrorResponse(data.response)) return thunkApi.rejectWithValue(getErrorCode(data.response));

  const { documentId, signedUploadUrl } = data.response;
  return {
    documentId,
    signedUploadUrl,
  };
});

export const deleteDocument = createAsyncThunk(
  "signUp/deleteDocument",
  async (parameters: { token: string; documentId: string; fileType: string }, thunkApi) => {
    const { token, documentId, fileType } = parameters;
    const response = await fetch(`${settings.apiEndpoint.deleteDocument}/${documentId}.${fileType.split("/")[1]}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const data = (await response.json()) as APIResponse<Record<string, unknown>>;
    if (isErrorResponse(data.response)) return thunkApi.rejectWithValue(getErrorCode(data.response));
    return;
  }
);

const initialState: SignUpState = {
  config: constants.FORM_CONFIG,
  lead: {
    id: "",
    token: "",
  },
  leadStatus: {
    loading: false,
    error: false,
    success: false,
  },
  salutation: {
    value: "",
    hasRequiredError: false,
  },
  name: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  idType: {
    value: "",
    hasRequiredError: false,
  },
  idValue: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  document: {
    files: {},
    status: {
      loading: false,
      error: false,
      success: false,
    },
  },
  dob: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
    isNotEligibleError: false,
    needAdditionalDoc: false,
  },
  gender: {
    value: "",
    hasRequiredError: false,
  },
  ethnic: {
    value: "",
    hasRequiredError: false,
  },
  language: {
    value: "",
    hasRequiredError: false,
  },
  residentialType: {
    value: null,
    hasRequiredError: false,
  },
  house: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  unit: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  block: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  building: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  streetName: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  streetType: {
    value: "",
    hasRequiredError: false,
  },
  street: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  postcode: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  postcodeStatus: {
    loading: false,
    success: false,
    error: false,
  },
  area: {
    value: "",
    hasRequiredError: false,
    items: [],
  },
  city: {
    value: "",
    hasRequiredError: false,
  },
  state: {
    value: "",
    hasRequiredError: false,
  },
  installationDate: {
    value: "",
    hasRequiredError: false,
  },
  installationTime: {
    value: "",
    hasRequiredError: false,
  },
  mobilePrefix: {
    value: "",
    hasRequiredError: false,
  },
  mobileNumber: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  alternateNumber: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  email: {
    value: "",
    hasRequiredError: false,
    hasInvalidError: false,
  },
  showError: false,
  cableType: "",
  serviceProvider: "",
  allowDishInstallation: false,
};

const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    salutationUpdated(state, action: PayloadAction<string>) {
      const salutation = action.payload;
      if (salutation !== state.salutation.value)
        state.salutation = {
          value: action.payload,
          hasRequiredError: false,
        };
    },
    nameUpdated(state, action: PayloadAction<string>) {
      const name = action.payload;
      if (name !== state.name.value) {
        state.name.value = name;
        state.name.hasRequiredError = state.config.name.isRequired && !name.trim();
        state.name.hasInvalidError = !!name && !new RegExp(state.config.regex.name).test(name);
      }
    },
    idTypeUpdated(state, action: PayloadAction<string>) {
      state.idType.value = action.payload;
      state.idType.hasRequiredError = false;
      state.idValue.value = "";
      state.idValue.hasRequiredError = state.config.id.isRequired;
      state.document.files = {};
    },
    nricUpdated(state, action: PayloadAction<string>) {
      const nric = action.payload;
      if (nric !== state.idValue.value) {
        // Extract dob and gender from nric
        state.idValue.value = nric;
        state.idValue.hasRequiredError = state.config.id.isRequired && !nric;
        const match = new RegExp("^(\\d{2})(\\d{2})(\\d{2})-\\d{2}-\\d{3}(\\d)$").exec(nric);
        state.idValue.hasInvalidError = !!nric && !match;
        if (match) {
          const [, yy, mm, dd, gender] = match;
          const currentYear = new Date().getUTCFullYear() % 2000;
          const y = parseInt(yy);
          const year = y <= currentYear ? y + 2000 : y + 1900;
          const month = parseInt(mm);
          const day = parseInt(dd);

          state.idValue.hasInvalidError =
            !isValidDate(year, month, day) || !fulfillMaxAge(year, month, day, state.config.dob.maxValidAge);
          if (!state.idValue.hasInvalidError) {
            state.dob.value = `${dd} / ${mm} / ${year}`;
            state.dob.hasRequiredError = false;
            state.dob.hasInvalidError = false;
            state.dob.isNotEligibleError = !fulfillMinAge(year, month, day, state.config.dob.minEligibleAge);
            state.dob.needAdditionalDoc = !fulfillMinAge(year, month, day, state.config.dob.noAdditionalDocAge);
            state.gender.value = parseInt(gender) % 2 === 0 ? Gender.female : Gender.male;
            state.gender.hasRequiredError = false;
          }
        }
      }
    },
    idValueUpdated(state, action: PayloadAction<string>) {
      const {
        idType: { value: idType },
        idValue: { value: idValue },
        config: {
          id: { types, isRequired },
        },
      } = state;
      const id = action.payload;
      if (idValue !== id) {
        state.idValue.value = id;
        state.idValue.hasRequiredError = isRequired && !id.trim();
        const type = types.find(({ value }) => value === idType);
        state.idValue.hasInvalidError = !!id && !new RegExp(type?.regex ?? "").test(id);
      }
    },
    documentSelected(state, action: PayloadAction<AddDocument>) {
      const { name, file } = action.payload;
      state.document.files[name] = {
        file,
      };
    },
    documentUploaded(state, action: PayloadAction<Required<AddDocument>>) {
      const { name, file, data, documentId } = action.payload;
      state.document.files[name] = {
        file,
        data,
        documentId,
      };
    },
    documentRemoved(state, action: PayloadAction<string>) {
      const name = action.payload;
      delete state.document.files[name];
    },
    dobUpdated(state, action: PayloadAction<string>) {
      const dob = action.payload;
      if (dob !== state.dob.value) {
        state.dob.value = dob;
        state.dob.hasRequiredError = state.config.dob.isRequired && !dob;

        const match = new RegExp("^(\\d{2}) / (\\d{2}) / (\\d{4})$").exec(dob);
        state.dob.hasInvalidError = !!dob && !match;
        state.dob.isNotEligibleError = false;
        state.dob.needAdditionalDoc = false;
        if (match) {
          const [, d, m, y] = match;
          const year = parseInt(y);
          const month = parseInt(m);
          const date = parseInt(d);
          state.dob.hasInvalidError =
            !isValidDate(year, month, date) || !fulfillMaxAge(year, month, date, state.config.dob.maxValidAge);
          state.dob.isNotEligibleError = !fulfillMinAge(year, month, date, state.config.dob.minEligibleAge);
          state.dob.needAdditionalDoc =
            !state.dob.hasInvalidError && !fulfillMinAge(year, month, date, state.config.dob.noAdditionalDocAge);
        }
      }
    },
    genderUpdated(state, action: PayloadAction<string>) {
      state.gender.value = action.payload;
      state.gender.hasRequiredError = false;
    },
    ethnicUpdated(state, action: PayloadAction<string>) {
      state.ethnic.value = action.payload;
      state.ethnic.hasRequiredError = false;
    },
    languageUpdated(state, action: PayloadAction<string>) {
      state.language.value = action.payload;
      state.language.hasRequiredError = false;
    },
    residentialTypeUpdated(state, action: PayloadAction<number>) {
      const type = action.payload;
      state.residentialType.value = type;
      state.residentialType.hasRequiredError = false;

      const {
        config: { street, postcode, area, city, state: stateConfig, houseNumber, unit, block, building },
      } = state;

      state.streetType.hasRequiredError = street.isRequired && !state.streetType.value;
      state.streetName.hasRequiredError = street.isRequired && !state.streetName.value.trim();
      state.area.hasRequiredError = area.isRequired && !state.area.value;
      state.postcode.hasRequiredError = postcode.isRequired && !state.postcode.value;
      state.city.hasRequiredError = city.isRequired && !state.city.value;
      state.state.hasRequiredError = stateConfig.isRequired && !state.state.value;

      if (type === ResidentialType.SingleDwelling) {
        state.house.hasRequiredError = houseNumber.isRequired && !state.house.value.trim();
        state.unit.hasRequiredError = false;
        state.block.hasRequiredError = false;
        state.building.hasRequiredError = false;
      } else if (type === ResidentialType.MultiDwelling) {
        state.house.hasRequiredError = false;
        state.unit.hasRequiredError = unit.isRequired && !state.unit.value.trim();
        state.block.hasRequiredError = block.isRequired && !state.block.value.trim();
        state.building.hasRequiredError = building.isRequired && !state.building.value.trim();
      }
    },
    houseUpdated(state, action: PayloadAction<string>) {
      const house = action.payload;
      if (house !== state.house.value) {
        state.house.value = house;
        state.house.hasRequiredError = state.config.houseNumber.isRequired && !house.trim();
        state.house.hasInvalidError = !!house && !new RegExp(state.config.regex.house).test(house);
      }
    },
    unitUpdated(state, action: PayloadAction<string>) {
      const unit = action.payload;
      if (unit !== state.unit.value) {
        state.unit.value = unit;
        state.unit.hasRequiredError = state.config.unit.isRequired && !unit.trim();
        state.unit.hasInvalidError = !!unit && !new RegExp(state.config.regex.unit).test(unit);
      }
    },
    blockUpdated(state, action: PayloadAction<string>) {
      const block = action.payload;
      if (block !== state.block.value) {
        state.block.value = block;
        state.block.hasRequiredError = state.config.block.isRequired && !block.trim();
        state.block.hasInvalidError = !!block && !new RegExp(state.config.regex.block).test(block);
      }
    },
    buildingUpdated(state, action: PayloadAction<string>) {
      const building = action.payload;
      if (building !== state.building.value) {
        state.building.value = building;
        state.building.hasRequiredError = state.config.building.isRequired && !building.trim();
        state.building.hasInvalidError = !!building && !new RegExp(state.config.regex.building).test(building);
      }
    },
    streetTypeUpdated(state, action: PayloadAction<string>) {
      const type = action.payload;
      if (type !== state.streetType.value) {
        state.streetType.value = type;
        state.streetType.hasRequiredError = false;
      }
    },
    streetNameUpdated(state, action: PayloadAction<string>) {
      const name = action.payload;
      if (name !== state.streetName.value) {
        state.streetName.value = name;
        state.streetName.hasRequiredError = state.config.street.isRequired && !name.trim();
        state.streetName.hasInvalidError = !!name && !new RegExp(state.config.regex.street).test(name);
      }
    },
    areaUpdated(state, action: PayloadAction<string>) {
      const area = action.payload;
      if (area !== state.area.value) {
        state.area.value = area;
        state.area.hasRequiredError = false;
      }
    },
    postcodeUpdated(state, action: PayloadAction<UpdatePostcode>) {
      const {
        config: {
          area: { isRequired: isAreaRequired },
          city: { isRequired: isCityRequired },
          state: { isRequired: isStateRequired },
        },
      } = state;
      const { postcode, hasRequiredError, hasInvalidError } = action.payload;
      state.postcode.value = postcode;
      state.postcode.hasRequiredError = hasRequiredError;
      state.postcode.hasInvalidError = hasInvalidError;

      if (!postcode || hasRequiredError || hasInvalidError) {
        state.postcodeStatus = {
          loading: false,
          error: false,
          success: false,
        };

        state.area.items = [];
        state.area.value = "";
        state.area.hasRequiredError = isAreaRequired;
        state.city.value = "";
        state.city.hasRequiredError = isCityRequired;
        state.state.value = "";
        state.state.hasRequiredError = isStateRequired;
      }
    },
    installationDateUpdated(state, action: PayloadAction<string>) {
      const date = action.payload;
      if (date !== state.installationDate.value) {
        state.installationDate.value = date;
        state.installationDate.hasRequiredError = false;
      }
    },
    installationTimeUpdated(state, action: PayloadAction<string>) {
      const time = action.payload;
      if (time !== state.installationTime.value) {
        state.installationTime.value = time;
        state.installationTime.hasRequiredError = false;
      }
    },
    mobilePrefixUpdated(state, action: PayloadAction<string>) {
      const prefix = action.payload;
      if (prefix !== state.mobilePrefix.value) {
        state.mobilePrefix.value = prefix;
        state.mobilePrefix.hasRequiredError = false;
        const mobile = state.mobileNumber.value;
        mobile && new RegExp(state.config.regex.mobile).test(mobile) && (state.mobileNumber.hasInvalidError = false);
      }
    },
    mobileNumberUpdated(state, action: PayloadAction<string>) {
      const mobile = action.payload;
      if (mobile !== state.mobileNumber.value) {
        state.mobileNumber.value = mobile;
        state.mobileNumber.hasRequiredError = state.config.mobileNumber.isRequired && !mobile.trim();
        state.mobileNumber.hasInvalidError = !!mobile && !new RegExp(state.config.regex.mobile).test(mobile);
      }
    },
    alternateNumberUpdated(state, action: PayloadAction<string>) {
      const phone = action.payload;
      if (phone !== state.alternateNumber.value) {
        state.alternateNumber.value = phone;
        state.alternateNumber.hasRequiredError = state.config.alternateNumber.isRequired && !phone.trim();
        state.alternateNumber.hasInvalidError = !!phone && !new RegExp(state.config.regex.alternateNumber).test(phone);
      }
    },
    emailUpdated(state, action: PayloadAction<UpdateEmail>) {
      const { email, isValid } = action.payload;
      if (email !== state.email.value) {
        state.email.value = email;
        state.email.hasRequiredError = state.config.email.isRequired && !email.trim();
        state.email.hasInvalidError = !!email && !isValid;
      }
    },
    signUpValidated(state, action: PayloadAction<void>) {
      if (!isComplete(state)) state.showError = true;
    },
    hideSignUpError(state) {
      state.showError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerInfo.fulfilled, (state, action) => {
        const {
          payload: {
            response: {
              individual: { title, name, idType, idSuffix, email, phoneNumber },
            },
          },
        } = action;

        state.salutation = {
          value: title,
          hasRequiredError: false,
        };
        state.name = {
          value: name,
          hasInvalidError: false,
          hasRequiredError: false,
        };
        state.idType = {
          value: idType,
          hasRequiredError: false,
        };
        state.idValue = {
          value: idSuffix,
          hasInvalidError: false,
          hasRequiredError: false,
        };
        state.email = {
          value: email,
          hasInvalidError: false,
          hasRequiredError: false,
        };
        state.mobilePrefix = {
          value: phoneNumber.slice(0, 3),
          hasRequiredError: false,
        };
        state.mobileNumber = {
          value: phoneNumber.slice(3),
          hasRequiredError: false,
          hasInvalidError: false,
        };
        state.gender = {
          value: "",
          hasRequiredError: false,
        };
        state.ethnic = {
          value: "",
          hasRequiredError: false,
        };
        state.dob = {
          value: "",
          hasRequiredError: false,
          hasInvalidError: false,
          isNotEligibleError: false,
          needAdditionalDoc: false,
        };
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        const { form } = action.payload;
        if (form) state.config = form;
      })
      .addCase(fetchPostcode.pending, (state, action) => {
        state.postcodeStatus = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(fetchPostcode.rejected, (state, action) => {
        state.postcodeStatus = {
          loading: false,
          success: false,
          error: true,
        };
        if (action.payload) state.postcodeStatus.errorCode = action.payload;

        state.area.value = "";
        state.area.items = [];
        state.area.hasRequiredError = state.config.area.isRequired;
        state.city.value = "";
        state.city.hasRequiredError = state.config.city.isRequired;
        state.state.value = "";
        state.state.hasRequiredError = state.config.state.isRequired;
      })
      .addCase(fetchPostcode.fulfilled, (state, action) => {
        const { area, city, state: stateValue } = action.payload;
        state.postcodeStatus = {
          loading: false,
          success: true,
          error: false,
        };

        state.area.value = "";
        state.area.items = area.sort();
        state.area.hasRequiredError = state.config.area.isRequired;
        state.city.value = city;
        state.city.hasRequiredError = false;
        state.state.value = stateValue;
        state.state.hasRequiredError = false;
      })
      .addCase(generateLead.pending, (state, action) => {
        state.leadStatus = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(generateLead.rejected, (state, action) => {
        state.leadStatus = {
          loading: false,
          success: false,
          error: true,
        };
        if (action.payload) state.leadStatus.errorCode = action.payload;
      })
      .addCase(generateLead.fulfilled, (state, action) => {
        state.leadStatus = {
          loading: false,
          success: true,
          error: false,
        };
        const { leadId, token } = action.payload;
        state.lead.id = leadId;
        state.lead.token = token;
      })
      .addCase(updateLead.pending, (state) => {
        state.leadStatus = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.leadStatus = {
          loading: false,
          success: false,
          error: true,
        };
        if (action.payload) {
          const { errorCode, invalidMobile, invalidAlternateNumber, invalidEmail } = action.payload;
          if (invalidMobile) state.mobileNumber.hasInvalidError = true;
          if (invalidAlternateNumber) state.alternateNumber.hasInvalidError = true;
          if (invalidEmail) state.email.hasInvalidError = true;
          if (invalidMobile || invalidAlternateNumber || invalidEmail) {
            state.showError = true;
            state.leadStatus.error = false;
          } else if (errorCode) state.leadStatus.errorCode = errorCode;
        }
      })
      .addCase(updateLead.fulfilled, (state) => {
        state.leadStatus = {
          loading: false,
          error: false,
          success: true,
        };
      })
      .addCase(generateSignedUrl.pending, (state) => {
        state.document.status = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(generateSignedUrl.rejected, (state, action) => {
        state.document.status = {
          loading: false,
          success: false,
          error: true,
        };
        if (action.payload) state.document.status.errorCode = action.payload as string;
      })
      .addCase(generateSignedUrl.fulfilled, (state) => {
        state.document.status = {
          loading: false,
          success: true,
          error: false,
        };
      })
      .addCase(deleteDocument.pending, (state) => {
        state.document.status = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.document.status = {
          loading: false,
          success: false,
          error: true,
        };
        if (action.payload) state.document.status.errorCode = action.payload as string;
      })
      .addCase(deleteDocument.fulfilled, (state) => {
        state.document.status = {
          loading: false,
          success: true,
          error: false,
        };
      })
      .addCase(confirmAddress, (state, action) => {
        const {
          cableType,
          serviceProvider,
          residentialType,
          postcode,
          houseNumber,
          building,
          unit,
          block,
          street,
          area,
          city,
          state: negeri,
          allowDishInstallation,
        } = action.payload;

        // TODO: temporary correct casing until backend fix
        const { cableTypeMap, serviceProviderMap } = state.config;
        state.cableType = cableTypeMap[cableType.toLowerCase()] ?? cableType;
        state.serviceProvider = serviceProviderMap[serviceProvider.toLowerCase()] ?? serviceProvider;
        state.allowDishInstallation = !!allowDishInstallation;

        // TODO: refactor
        if (residentialType) {
          state.residentialType.value = residentialType;
          state.residentialType.hasRequiredError = false;

          const {
            config: { postcode, city, state: stateConfig, houseNumber, unit, block, building },
          } = state;

          /* NOTE: Removed error checking states retrieved from config since error is always false in BB flow. */
          // state.streetType.hasRequiredError = street.isRequired && !state.streetType.value;
          // state.streetName.hasRequiredError = street.isRequired && !state.streetName.value;
          // state.area.hasRequiredError = area.isRequired && !state.area.value;
          state.postcode.hasRequiredError = postcode.isRequired && !state.postcode.value;
          state.city.hasRequiredError = city.isRequired && !state.city.value;
          state.state.hasRequiredError = stateConfig.isRequired && !state.state.value;

          if (residentialType === ResidentialType.SingleDwelling) {
            state.house.hasRequiredError = houseNumber.isRequired && !state.house.value;
            state.unit.hasRequiredError = false;
            state.block.hasRequiredError = false;
            state.building.hasRequiredError = false;
          } else if (residentialType === ResidentialType.MultiDwelling) {
            state.house.hasRequiredError = false;
            state.unit.hasRequiredError = unit.isRequired && !state.unit.value;
            state.block.hasRequiredError = block.isRequired && !state.block.value;
            state.building.hasRequiredError = building.isRequired && !state.building.value;
          }
        }

        /**
         * NOTE:
         * Since this action is only called by BB flow's confirm address, the code needs to set street field's error to false
         * because `streetName` and `town` field in Update Lead API is optional.
         *
         * There is a posibility that Address Checker API to return an address without a street name. If user selects that
         * address, the `street` state will have an empty which will cause sign up form to not be able to complete. Hence,
         * the following lines is to ignore the street field error checking.
         *
         * However, for TV flow, street type and street name are both required fields. Thus, that error checking is not modified.
         */
        state.street.value = street;
        state.street.hasRequiredError = false;
        state.street.hasInvalidError = false;
        state.streetType.hasRequiredError = false;
        state.streetName.hasRequiredError = false;
        state.area.value = area;
        state.area.hasRequiredError = false;

        if (building) {
          state.building.value = building;
          state.building.hasRequiredError = state.config.building.isRequired && !building;
          state.building.hasInvalidError = !!building && !new RegExp(state.config.regex.building).test(building);
        }
        if (postcode) {
          state.postcode = {
            value: postcode,
            hasRequiredError: false,
            hasInvalidError: false,
          };
        }
        if (area) {
          state.area.value = area;
          state.area.hasRequiredError = false;
        }
        if (city) {
          state.city.value = city;
          state.city.hasRequiredError = false;
        }
        if (negeri) {
          state.state.value = negeri;
          state.state.hasRequiredError = false;
        }
        if (houseNumber) {
          state.house.value = houseNumber;
          state.house.hasRequiredError = state.config.houseNumber.isRequired && !houseNumber;
          state.house.hasInvalidError = !!houseNumber && !new RegExp(state.config.regex.house).test(houseNumber);
        }
        if (unit) {
          state.unit.value = unit;
          state.unit.hasRequiredError = state.config.unit.isRequired && !unit;
          state.unit.hasInvalidError = !!unit && !new RegExp(state.config.regex.unit).test(unit);
        }
        if (block) {
          state.block.value = block;
          state.block.hasRequiredError = state.config.block.isRequired && !block;
          state.block.hasInvalidError = !!block && !new RegExp(state.config.regex.block).test(block);
        }
      })
      .addMatcher(isInitAction, (state, action) => {
        const {
          name,
          id,
          dob,
          gender,
          ethnic,
          language,
          residentialType,
          houseNumber,
          unit,
          block,
          building,
          street,
          postcode,
          area,
          city,
          state: stateConfig,
          installationDate,
          installationTime,
          mobileNumber,
          alternateNumber,
          email,
        } = state.config;

        if (name.isRequired) {
          state.name.hasRequiredError = true;
          state.salutation.hasRequiredError = true;
        }
        const initialSalutation = name.salutations.find(({ value }) => value === name.preselectedSalutationValue)
          ?.value;
        if (initialSalutation) {
          state.salutation.value = initialSalutation as string;
          state.salutation.hasRequiredError = false;
        }

        if (id.isRequired) {
          state.idType.hasRequiredError = true;
          state.idValue.hasRequiredError = true;
        }
        const initialIdType = id.types.find(({ value }) => id.preselectedTypeValue)?.value;
        if (initialIdType) {
          state.idType.value = initialIdType;
          state.idType.hasRequiredError = false;
        }

        if (dob.isRequired) state.dob.hasRequiredError = true;

        const initialGender = gender.items.find(({ value }) => value === gender.preselectedValue)?.value;
        if (initialGender) state.gender.value = initialGender as string;
        else if (gender.isRequired) state.gender.hasRequiredError = true;

        const initialEthnic = ethnic.items.find(({ value }) => value === ethnic.preselectedValue)?.value;
        if (initialEthnic) state.ethnic.value = initialEthnic as string;
        else if (ethnic.isRequired) state.ethnic.hasRequiredError = true;

        const initialLanguage = language.items.find(({ value }) => value === language.preselectedValue)?.value;
        if (initialLanguage) state.language.value = initialLanguage as string;
        else if (language.isRequired) state.language.hasRequiredError = true;

        const initialResidentialType = residentialType.items.find(
          ({ value }) => value === residentialType.preselectedValue
        )?.value;
        if (initialResidentialType) {
          state.residentialType.value = initialResidentialType as number;

          if (street.isRequired) {
            state.streetName.hasRequiredError = true;
            state.streetType.hasRequiredError = true;
          }
          const initialStreetType = street.types.find(({ value }) => value === street.preselectedTypeValue)?.value;
          if (initialStreetType) {
            state.streetType.value = initialStreetType as string;
            state.streetType.hasRequiredError = false;
          }

          if (postcode.isRequired) state.postcode.hasRequiredError = true;

          if (area.isRequired) state.area.hasRequiredError = true;

          if (city.isRequired) state.city.hasRequiredError = true;

          if (stateConfig.isRequired) state.state.hasRequiredError = true;

          if (initialResidentialType === ResidentialType.SingleDwelling) {
            if (houseNumber.isRequired) state.house.hasRequiredError = true;
          } else if (initialResidentialType === ResidentialType.MultiDwelling) {
            if (unit.isRequired) state.unit.hasRequiredError = true;
            if (block.isRequired) state.block.hasRequiredError = true;
            if (building.isRequired) state.building.hasRequiredError = true;
          }
        } else if (residentialType.isRequired) state.residentialType.hasRequiredError = true;

        const isBroadband = !!action.payload.selectedSpeed;
        if (isFeatureEnabledForPortal(isBroadband, installationDate.isRequired))
          state.installationDate.hasRequiredError = true;

        const initialInstallationTime = installationTime.items.find(
          ({ value }) => value === installationTime.preselectedValue
        )?.value;
        if (initialInstallationTime) state.installationTime.value = initialInstallationTime as string;
        else if (isFeatureEnabledForPortal(isBroadband, installationTime.isRequired))
          state.installationTime.hasRequiredError = true;

        const initialMobilePrefix = mobileNumber.prefixes.find(({ value }) => mobileNumber.preselectedPrefixValue)
          ?.value;
        if (initialMobilePrefix) state.mobilePrefix.value = initialMobilePrefix as string;
        else if (mobileNumber.isRequired) {
          state.mobilePrefix.hasRequiredError = true;
          state.mobileNumber.hasRequiredError = true;
        }

        if (alternateNumber.isRequired) state.alternateNumber.hasRequiredError = true;

        if (email.isRequired) state.email.hasRequiredError = true;
      });
  },
});

const {
  salutationUpdated,
  nameUpdated,
  idTypeUpdated,
  nricUpdated,
  idValueUpdated,
  documentSelected,
  documentUploaded,
  documentRemoved,
  dobUpdated,
  genderUpdated,
  ethnicUpdated,
  languageUpdated,
  residentialTypeUpdated,
  houseUpdated,
  unitUpdated,
  blockUpdated,
  buildingUpdated,
  streetTypeUpdated,
  streetNameUpdated,
  areaUpdated,
  postcodeUpdated,
  installationDateUpdated,
  installationTimeUpdated,
  mobilePrefixUpdated,
  mobileNumberUpdated,
  alternateNumberUpdated,
  emailUpdated,
  signUpValidated,
  hideSignUpError,
} = signUpSlice.actions;

export {
  salutationUpdated,
  nameUpdated,
  idTypeUpdated,
  nricUpdated,
  idValueUpdated,
  documentSelected,
  documentRemoved,
  dobUpdated,
  genderUpdated,
  ethnicUpdated,
  languageUpdated,
  residentialTypeUpdated,
  houseUpdated,
  unitUpdated,
  blockUpdated,
  buildingUpdated,
  streetTypeUpdated,
  streetNameUpdated,
  areaUpdated,
  postcodeUpdated,
  installationDateUpdated,
  installationTimeUpdated,
  mobilePrefixUpdated,
  mobileNumberUpdated,
  alternateNumberUpdated,
  emailUpdated,
  signUpValidated,
  hideSignUpError,
};

export default signUpSlice.reducer;

/* Thunks */
export const changeIdType = (idType: string): AppThunk => (dispatch, getState) => {
  const {
    signUp: {
      document: { files },
      lead: { token },
    },
  } = getState();
  dispatch(idTypeUpdated(idType));
  const listOfFiles = Object.keys(files);
  for (const name of listOfFiles) {
    const { documentId, file } = files[name];
    if (documentId) dispatch(deleteDocument({ token, documentId, fileType: file.type }));
  }
};

export const addDocument = (name: string, file: File, data: string, documentId: string): AppThunk => (
  dispatch,
  getState
) => {
  const {
    signUp: {
      document: { files },
      lead: { token },
    },
  } = getState();
  if (files[name]) {
    const { documentId, file } = files[name];
    if (documentId) dispatch(deleteDocument({ token, documentId, fileType: file.type }));
  }
  dispatch(documentUploaded({ name, file, data, documentId }));
};

export const removeDocument = (name: string): AppThunk => (dispatch, getState) => {
  const {
    signUp: {
      document: { files },
      lead: { token },
    },
  } = getState();
  if (files[name]) {
    const { documentId, file } = files[name];
    if (documentId) dispatch(deleteDocument({ token, documentId, fileType: file.type }));
    dispatch(documentRemoved(name));
  }
};

export const updateEmail = (email: string): AppThunk => (dispatch, getState) => {
  const {
    signUp: {
      email: { value },
      config: {
        regex: { email: regex },
      },
    },
  } = getState();
  if (email !== value) {
    dispatch(
      emailUpdated({
        email,
        isValid: new RegExp(regex).test(email),
      })
    );
    dispatch(fieldUpdated({ field: "email", value: email }));
  }
};

export const updatePostcode = (postcode: string): AppThunk => (dispatch, getState) => {
  const {
    postcode: { value },
    config: {
      postcode: { isRequired },
    },
  } = getState().signUp;

  if (postcode !== value) {
    const hasRequiredError = isRequired && !postcode;
    const hasInvalidError = !!postcode && !new RegExp(/^[0-9]{5}$/).test(postcode);
    const payload: UpdatePostcode = {
      postcode,
      hasRequiredError,
      hasInvalidError,
    };
    dispatch(postcodeUpdated(payload));
    if (!!postcode && !hasRequiredError && !hasInvalidError) dispatch(fetchPostcode(postcode));
  }
};

export const submitLead = (): AppThunk => async (dispatch, getState) => {
  const appState = getState();
  const {
    signUp: {
      lead: { id: leadId },
    },
  } = appState;

  if (!leadId)
    await dispatch(generateLead({ ...getBasicLeadParameters(appState), ...getGATrackingLeadParameters(appState) }));
  if (getState().signUp.leadStatus.success) dispatch(signUpPageLoad());
};

/* Analytics actions */
export const signUpPageLoad = createAction("signUp/pageLoad");
export const trackWhyUploadDocument = createAction("signUp/whyUploadDocument");
export const trackProceedToStandardMethod = createAction("signUp/disablePnpProceed");

/* Utility functions */
export function isFeatureEnabledForPortal(isBroadband: boolean, feature: number) {
  return isBroadband ? !!(feature & PortalType.BB) : !!(feature & PortalType.TV);
}

export function isComplete(signUpState: SignUpState): boolean {
  const {
    config,
    salutation,
    name,
    idType,
    idValue,
    document,
    dob,
    gender,
    ethnic,
    language,
    residentialType,
    house,
    unit,
    block,
    building,
    streetName,
    streetType,
    postcode,
    area,
    city,
    state,
    installationDate,
    installationTime,
    mobilePrefix,
    mobileNumber,
    alternateNumber,
    email,
  } = signUpState;

  const valid =
    !salutation.hasRequiredError &&
    !name.hasRequiredError &&
    !name.hasInvalidError &&
    !idType.hasRequiredError &&
    !idValue.hasRequiredError &&
    !idValue.hasInvalidError &&
    !dob.hasRequiredError &&
    !dob.hasInvalidError &&
    !dob.isNotEligibleError &&
    !gender.hasRequiredError &&
    !ethnic.hasRequiredError &&
    !language.hasRequiredError &&
    !residentialType.hasRequiredError &&
    !house.hasRequiredError &&
    !house.hasInvalidError &&
    !unit.hasRequiredError &&
    !unit.hasInvalidError &&
    !block.hasRequiredError &&
    !block.hasInvalidError &&
    !building.hasRequiredError &&
    !building.hasInvalidError &&
    !streetName.hasRequiredError &&
    !streetName.hasInvalidError &&
    !streetType.hasRequiredError &&
    !postcode.hasRequiredError &&
    !postcode.hasInvalidError &&
    !area.hasRequiredError &&
    !city.hasRequiredError &&
    !state.hasRequiredError &&
    !installationDate.hasRequiredError &&
    !installationTime.hasRequiredError &&
    !mobilePrefix.hasRequiredError &&
    !mobileNumber.hasRequiredError &&
    !mobileNumber.hasInvalidError &&
    !alternateNumber.hasRequiredError &&
    !alternateNumber.hasInvalidError &&
    !email.hasRequiredError &&
    !email.hasInvalidError;

  if (valid)
    if (config.document.isRequired)
      return (
        config.id.types
          .find(({ value }) => value === idType.value)
          ?.documents.every((doc) => !!document.files[doc]?.documentId) ?? false
      );
  return valid;
}

function isLeapYear(year: number): boolean {
  if (year % 4 === 0) {
    if (year % 100 === 0 && year % 400 !== 0) {
    } else return true;
  }
  return false;
}

function isValidDate(year: number, month: number, day: number): boolean {
  let valid = true;
  valid = valid && month < 13 && month > 0;
  valid = valid && day > 0;
  const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
  if (month === 2)
    if (isLeapYear(year)) valid = valid && day < 30;
    else valid = valid && day < 29;
  else if (monthsWith31Days.includes(month)) valid = valid && day < 32;
  else valid = valid && day < 31;
  return valid;
}

function fulfillMinAge(dobYear: number, dobMonth: number, dobDate: number, minAge: number): boolean {
  const today = new Date();
  const msianDate = new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      today.getUTCHours() + 8,
      today.getUTCMinutes(),
      today.getUTCSeconds()
    )
  );
  let valid = false;
  const yearDiff = msianDate.getUTCFullYear() - dobYear;
  if (yearDiff > minAge) valid = true;
  else if (yearDiff === minAge) {
    const monthNow = msianDate.getUTCMonth() + 1;
    if (dobMonth < monthNow) valid = true;
    else if (dobMonth === monthNow) {
      const dateNow = msianDate.getUTCDate();
      if (dobDate <= dateNow) valid = true;
    }
  }
  return valid;
}

function fulfillMaxAge(dobYear: number, dobMonth: number, dobDate: number, maxAge: number): boolean {
  const today = new Date();
  const msianDate = new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      today.getUTCHours() + 8,
      today.getUTCMinutes(),
      today.getUTCSeconds()
    )
  );
  let valid = false;
  const yearDiff = msianDate.getUTCFullYear() - dobYear;
  if (yearDiff < maxAge) valid = true;
  else if (yearDiff === maxAge) {
    const monthNow = msianDate.getUTCMonth() + 1;
    if (dobMonth > monthNow) valid = true;
    else if (dobMonth === monthNow) {
      const dateNow = msianDate.getUTCDate();
      if (dobDate >= dateNow) valid = true;
    }
  }
  return valid;
}
