import { createAsyncThunk, createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

import settings from "@settings";
import { AppState, AppThunk } from "@store";
import { OtpState, VerifyOtpRequest, GenerateOtpResponse, GenerateOtpRequest } from "./types";
import { fetchConfig } from "../config";
import { APIResponse, isErrorResponse, getErrorCode } from "@lib/api";
import { updateLead } from "@ducks/signUp";
import { pushWithParams } from "@ducks/route";
import { orderSummaryPath } from "@constants/paths";

const requestOtpGeneration = createAsyncThunk<string, undefined, { state: AppState; rejectValue: string | null }>(
  "otp/generateOtp",
  async (request, thunkApi) => {
    const {
      lead: { token },
      mobilePrefix: { value: prefix },
      mobileNumber: { value: number },
    } = thunkApi.getState().signUp;
    const phoneNumber = prefix + number;

    const body: GenerateOtpRequest = { phoneNumber };
    process.env.REACT_APP_USE_MOCK_OTP === "true" &&
      phoneNumber === process.env.REACT_APP_MOCK_OTP_PHONE_NUMBER &&
      (body.mock = true);

    const response = await fetch(settings.apiEndpoint.generateOtp, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = (await response.json()) as APIResponse<GenerateOtpResponse>;

    if (isErrorResponse(data.response)) return thunkApi.rejectWithValue(getErrorCode(data.response));
    return data.response.code;
  }
);

const verifyOtp = createAsyncThunk<void, string, { state: AppState; rejectValue: string | null }>(
  "otp/verifyOtp",
  async (otp, thunkApi) => {
    const {
      signUp: {
        lead: { token },
        mobileNumber: { value: number },
        mobilePrefix: { value: prefix },
      },
      otp: { code },
    } = thunkApi.getState();
    const phoneNumber = prefix + number;

    const body: VerifyOtpRequest = { phoneNumber, code, otp };
    process.env.REACT_APP_USE_MOCK_OTP === "true" &&
      phoneNumber === process.env.REACT_APP_MOCK_OTP_PHONE_NUMBER &&
      otp === process.env.REACT_APP_MOCK_OTP &&
      (body.mock = true);

    const response = await fetch(settings.apiEndpoint.verifyOtp, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = (await response.json()) as APIResponse<Record<string, unknown>>;
    if (isErrorResponse(data.response)) {
      return thunkApi.rejectWithValue(getErrorCode(data.response));
    }
  }
);

const initialState: OtpState = {
  config: {
    maxAttempts: 3,
    resendDuration: 30,
    blockedCharactersRegex: "",
    length: 0,
  },
  verificationRequired: true,
  verified: false,
  code: "",
  generationAttemptsRemaining: 3,
  verificationAttempts: 0,
  generationTime: 0,
  generationStatus: {
    loading: false,
    error: false,
    success: false,
  },
  verificationStatus: {
    loading: false,
    error: false,
    success: false,
  },
  showOtpModal: false,
  resendCountdownStarted: false,
};

const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    resetAttempts(state) {
      state.generationAttemptsRemaining = state.config.maxAttempts;
      state.verificationAttempts = 0;
      state.generationStatus = {
        loading: false,
        error: false,
        success: false,
      };
      state.verificationStatus = {
        loading: false,
        error: false,
        success: false,
      };
    },
    closeOtpModal(state) {
      state.showOtpModal = false;
    },
    resendCountdownToggled(state, action: PayloadAction<boolean>) {
      state.resendCountdownStarted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.fulfilled, (state, action) => {
        if (action.payload.otp) {
          state.config = action.payload.otp;
          state.generationAttemptsRemaining = state.config.maxAttempts;
        }
      })
      .addCase(requestOtpGeneration.pending, (state) => {
        state.generationStatus = {
          loading: true,
          error: false,
          success: false,
        };
        state.verificationStatus = {
          loading: false,
          error: false,
          success: false,
        };
        state.generationTime = Date.now();
        state.generationAttemptsRemaining--;
        state.resendCountdownStarted = true;
      })
      .addCase(requestOtpGeneration.rejected, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        if (action.payload) state.generationStatus.errorCode = action.payload;
      })
      .addCase(requestOtpGeneration.fulfilled, (state, action) => {
        state.generationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.code = action.payload;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.verificationStatus = {
          loading: true,
          error: false,
          success: false,
        };
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.verificationStatus = {
          loading: false,
          error: true,
          success: false,
        };
        state.verificationAttempts++;
        state.verified = false;
        if (action.payload) state.verificationStatus.errorCode = action.payload;
      })
      .addCase(verifyOtp.fulfilled, (state) => {
        state.verificationStatus = {
          loading: false,
          error: false,
          success: true,
        };
        state.verificationAttempts++;
        state.verified = true;
        state.showOtpModal = false;
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        state.verificationRequired = action.payload.verificationRequired;
        if (!state.showOtpModal) state.showOtpModal = true;
      });
  },
});

export const { resetAttempts, closeOtpModal, resendCountdownToggled } = otpSlice.actions;

export default otpSlice.reducer;

export const generateOtp = (): AppThunk => (dispatch, getState) => {
  if (getState().otp.generationAttemptsRemaining) {
    dispatch(requestOtpGeneration());
  }
};

export const submitOtp = (otp: string): AppThunk => async (dispatch) => {
  const result = await dispatch(verifyOtp(otp));
  if (verifyOtp.fulfilled.match(result)) {
    dispatch(trackVerifyOtp());
    dispatch(pushWithParams(orderSummaryPath));
  }
};

/* Analytics actions */
export const viewOtpModal = createAction("otp/viewOtpModal");
export const trackResendOtp = createAction("otp/resendOtp");
export const trackEditMobileNumber = createAction("otp/trackEditMobileNumber");
export const trackVerifyOtp = createAction("otp/trackVerifyOtp");
