import createTypography from "@material-ui/core/styles/createTypography";
import { FontFace } from "csstype";
import palette from "./palette";

const assetPath = "https://acm-assets.eco.astro.com.my/fonts";

export const fsAlbertPro: FontFace = {
  fontFamily: "FS Albert Pro",
  src: `
    local('FS Albert Pro'),
    local('FSAlbertPro'),
    url('${assetPath}/FSAlbertPro.woff2') format('woff2'),
    url('${assetPath}/FSAlbertPro.woff') format('woff')
  `,
  fontStyle: "normal",
  fontWeight: "normal",
  fontDisplay: "swap",
};

export const fsAlbertProBold: FontFace = {
  fontFamily: "FS Albert Pro",
  src: `
    local('FS Albert Pro Bold'),
    local('FSAlbertPro-Bold'),
    url('${assetPath}/FSAlbertPro-Bold.woff2') format('woff2'),
    url('${assetPath}/FSAlbertPro-Bold.woff') format('woff')
  `,
  fontStyle: "normal",
  fontWeight: "bold",
  fontDisplay: "swap",
};

export const fsAlbertProExtraBold: FontFace = {
  fontFamily: "FS Albert Pro",
  src: `
    local('FS Albert Pro ExtraBold'),
    local('FSAlbertPro-ExtraBold'),
    url('${assetPath}/FSAlbertPro-ExtraBold.woff2') format('woff2'),
    url('${assetPath}/FSAlbertPro-ExtraBold.woff') format('woff')
  `,
  fontStyle: "normal",
  fontWeight: 800,
  fontDisplay: "swap",
};

const typography = createTypography(palette, {
  htmlFontSize: 16,
  fontFamily: [
    `"FS Albert Pro"`,
    `-apple-system`,
    `BlinkMacSystemFont`,
    `"Segoe UI"`,
    `Roboto`,
    `"Helvetica Neue"`,
    `Arial`,
    "Microsoft YaHei",
    "微软雅黑",
    "STXihei",
    "华文细黑",
    `sans-serif`,
    `"Apple Color Emoji"`,
    `"Segoe UI Emoji"`,
    `"Segoe UI Symbol"`,
  ].join(","),
  button: {
    fontWeight: "bold",
  },
});

export default typography;
