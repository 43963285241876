import { Overrides } from "@material-ui/core/styles/overrides";

import { fsAlbertPro, fsAlbertProBold, fsAlbertProExtraBold } from "../typography";
import palette from "../palette";
import globalStyles from "./globalStyles";
import calendarOverrides from "./calendar";

const overrides: Overrides = {
  MuiCssBaseline: {
    "@global": {
      "@font-face": [fsAlbertPro, fsAlbertProBold, fsAlbertProExtraBold],
      ...globalStyles,
    },
  },
  MuiButton: {
    root: {
      padding: "13px 18px",
      lineHeight: 1,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    text: {
      fontWeight: "normal",
      fontSize: "1rem",
      padding: "0",
      textTransform: "unset",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    textPrimary: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    textSecondary: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    outlined: {
      padding: "11px 18px",
      border: `solid 2px ${palette.text.primary}`,
    },
    contained: {
      "&$disabled": {
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.15)",
      },
      "&:hover": {
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.15)",
        },
      },
    },
    containedPrimary: {
      "&:hover": {
        backgroundColor: palette.primary.main,
      },
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      /**
       * To fix backdrop shifting while scrolling on mobile,
       * add additional height 80px ~(rough height of address bar on mobile browsers)
       * and offset the child element using `transform: "translateY(-40px)` half of
       * additional height".
       * NOTE: Using transform instead of margin to avoid collision in the future.
       */
      height: "calc(100% + 80px)",
      "& > *": {
        transform: "translateY(-40px)",
      },
    },
  },
  MuiIconButton: {
    root: {
      padding: "1rem",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  MuiCircularProgress: {
    circle: {
      strokeLinecap: "round",
    },
  },
  MuiInputBase: {
    input: {
      height: "2.5rem",
    },
  },
  MuiOutlinedInput: {
    root: {
      "&:hover $notchedOutline": {
        borderColor: palette.grey[400],
      },
      "&$focused $notchedOutline": {
        borderColor: palette.grey[400],
        borderWidth: "1px",
      },
      "&$disabled": {
        backgroundColor: palette.grey[100],
      },
    },
    adornedStart: {
      paddingLeft: "1rem",
    },
    notchedOutline: {
      borderColor: palette.grey[400],
      transition: "border-color 200ms ease-in-out",
    },
    input: {
      padding: "0 1rem",
    },
    inputAdornedStart: {
      paddingLeft: 0,
    },
  },
  MuiTextField: {
    root: {
      width: "100%",
    },
  },
  ...calendarOverrides,
};

export default overrides;
