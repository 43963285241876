/* Shop TV & BB plans */
export const orderSummaryPath = "order-summary";
export const signUpFormPath = "sign-up";
export const checkCoveragePath = "check-coverage";
export const thankYouPath = "order-success";
export const registerInterestPath = "register-thank-you";

/* Apps */
export const disneyActivatePath = "apps/disney";
export const disneyPurchasePath = "apps/disney/purchase";
export const disneyActivateThankYouPath = "apps/disney/activate/thankyou";
export const disneyActivateCompletePath = "apps/disney/activate/complete";
export const disneyActivatePendingPath = "apps/disney/activate/pending";
export const disneyPurchaseThankYouPath = "apps/disney/purchase/thankyou";
export const disneyContactPath = "apps/disney/contact";
export const disneyContactThankYouPath = "apps/disney/contact/thankyou";
export const disneyWaitingPath = "apps/disney/waiting";
