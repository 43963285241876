import { push, replace } from "connected-react-router";

import { AppThunk } from "@store";
import { getShopUrlQueryString } from "@ducks/shop";
import { isShopQueryParameter } from "@lib/urlQueryMap";

export const pushWithParams = (url: string): AppThunk => (dispatch, getState) => {
  const {
    router: {
      location: { search },
    },
    shop: { urlQueryParameters },
  } = getState();
  const urlQueryString = getShopUrlQueryString(urlQueryParameters, search);
  dispatch(
    push({
      pathname: url,
      search: urlQueryString,
    })
  );
};

export const pushPackSelection = (): AppThunk => (dispatch, getState) => {
  const {
    router: {
      location: { search },
    },
    shop: {
      tier: { pageUrl },
      urlQueryParameters,
    },
  } = getState();
  const urlQueryString = getShopUrlQueryString(urlQueryParameters, search, true);
  dispatch(
    push({
      pathname: pageUrl,
      search: urlQueryString,
    })
  );
};

export const resetUrlQueryString = (): AppThunk => (dispatch, getState) => {
  const {
    router: {
      location: { pathname, search },
    },
  } = getState();
  const searchParams = new URLSearchParams(search);
  const params = new URLSearchParams();
  searchParams.forEach((value, key) => {
    isShopQueryParameter(key) && params.set(key, value);
  });
  dispatch(replace({ pathname, search: params.toString() }));
};
